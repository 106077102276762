import { filter } from '@/support/Utilities';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { ManagementReport, ManagementReportPayload } from '@/models/ManagementReport';
import { Report } from '@/models/Report';
import { Rpc } from '@/models/Rpc';

@Component<PdfRapportage>({})
export default class PdfRapportage extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  public $pageTitle = 'PDF Rapportage';

  protected isLoading = true;

  protected isLoadingPdf = true;

  protected managementReport: ManagementReport | null = null

  protected activeTab = 'management_report';

  protected tabs = [
    {
      name: 'Management rapportage',
      key: 'management_report',
    },
    {
      name: 'Rapporten',
      key: 'reports',
    },
  ];

  protected payload: ManagementReportPayload = {
    content: {},
  };
  // #endregion

  // #region Lifecycle Hooks / Init

  public mounted(): void {
    this.initialize();
  }

  protected initialize(): void {
    this.emitBreadcrumb();
    this.getManagementReport();
  }

  protected emitBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'PDF Rapportages' },
          { name: this.managementReport ? this.managementReport.name : '' },
        ],
      });
  }
  // #endregion

  // #region Class methods

  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */

  protected async getManagementReport(): Promise<void> {
    if (! this.$route.params.id || ! this.$route.query.organization) {
      return;
    }

    this.isLoading = true;

    this.managementReport = await new ManagementReport()
      .dmz(`${this.$route.query.organization}`)
      .include('media')
      .find(this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.payload.name = this.managementReport?.name || '';
    this.payload.content.forecast_supply_expert = this.managementReport?.content?.forecast_supply_expert || null;
    this.payload.content.messages_received_img = this.managementReport?.content?.messages_received_img || null;
    this.payload.content.work_stock_img = this.managementReport?.content?.work_stock_img || null;

    this.isLoadingPdf = false;
    this.isLoading = false;
  }

  protected async updateManagementReport(): Promise<void> {
    if (! this.managementReport) {
      return;
    }

    this.isLoadingPdf = true;
    filter(this.payload);
    this.managementReport = await this.managementReport
      .dmz(`${this.$route.query.organization}`)
      .include('media')
      .update(this.payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isLoadingPdf = false;
  }

  protected async finalizeManagementReport(): Promise<void> {
    if (! this.managementReport) {
      return;
    }

    const payload = {
      signature: 'management-report:finalize',
      body: {
        management_report: this.managementReport.id,
        organization: this.$route?.query?.organization,
      },
    };

    await new Rpc()
      .rpcPost(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.getManagementReport();
  }
  // #endregion

  // #region Getters & Setters

  protected get tableFilters() {
    return {
      management_report: this.managementReport?.id,
    };
  }

  protected get reportModel(): Report {
    return new Report().dmz(`${this.$route?.query?.organization || ''}`);
  }
  // #endregion

  // #region @Watchers

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
  // #endregion
}
