var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", "py-0": "" } },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "250px"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          !_vm.isLoading
            ? [
                _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                  _c("h2", { staticClass: "elementSubTitle" }, [
                    _vm._v(
                      "PDF Rapportage - " + _vm._s(_vm.managementReport.name)
                    )
                  ])
                ]),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", md12: "" } },
                  [
                    _c(
                      "v-tabs",
                      {
                        attrs: { "slider-color": "secondary" },
                        model: {
                          value: _vm.activeTab,
                          callback: function($$v) {
                            _vm.activeTab = $$v
                          },
                          expression: "activeTab"
                        }
                      },
                      [
                        _vm._l(_vm.tabs, function(tab, index) {
                          return [
                            _c(
                              "v-tab",
                              {
                                key: "pdf-rapportage-tab-" + index,
                                staticClass: "tab__filled",
                                class: { active: _vm.activeTab === tab.key },
                                attrs: { href: "#" + tab.key }
                              },
                              [_vm._v(" " + _vm._s(tab.name) + " ")]
                            )
                          ]
                        }),
                        _c(
                          "v-tabs-items",
                          {
                            model: {
                              value: _vm.activeTab,
                              callback: function($$v) {
                                _vm.activeTab = $$v
                              },
                              expression: "activeTab"
                            }
                          },
                          [
                            _c(
                              "v-tab-item",
                              {
                                attrs: {
                                  lazy: true,
                                  transition: false,
                                  "reverse-transition": false,
                                  value: "management_report"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementPanel" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-inline-block" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "router-back",
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.back()
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("chevron_left")
                                            ]),
                                            _vm._v(" Terug ")
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: { wrap: "", "align-center": "" }
                                      },
                                      [
                                        _c("v-flex", { attrs: { xs6: "" } }, [
                                          _vm._v(" Status: "),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.managementReport.statusPill
                                              )
                                            }
                                          })
                                        ]),
                                        _vm.$store.state
                                          .isServiceOrganization &&
                                        !_vm.managementReport.isFinal
                                          ? [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs6: "",
                                                    "text-xs-right": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "MiButton",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: {
                                                        color: "secondary",
                                                        small: "true",
                                                        icon: "picture_as_pdf"
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.updateManagementReport()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Rapportage genereren "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "MiButton",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        small: "true",
                                                        icon: "picture_as_pdf"
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.finalizeManagementReport()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Rapportage Finalize "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs3: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Naam",
                                                      "hide-details": ""
                                                    },
                                                    model: {
                                                      value: _vm.payload.name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.payload,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "payload.name"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs3: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label:
                                                        "Ontvangen meldingen IMG",
                                                      type: "number",
                                                      "hide-details": ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.payload.content
                                                          .messages_received_img,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.payload.content,
                                                          "messages_received_img",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "payload.content.messages_received_img"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs3: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Werkvoorraad IMG",
                                                      type: "number",
                                                      "hide-details": ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.payload.content
                                                          .work_stock_img,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.payload.content,
                                                          "work_stock_img",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "payload.content.work_stock_img"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs3: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label:
                                                        "Prognose aanbod aan (naam lev.)",
                                                      type: "number",
                                                      "hide-details": ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.payload.content
                                                          .forecast_supply_expert,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.payload.content,
                                                          "forecast_supply_expert",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "payload.content.forecast_supply_expert"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _vm.isLoading
                                      ? _c("LoaderCard", {
                                          attrs: {
                                            flat: "",
                                            type: "spinner--center",
                                            minHeight: "250px"
                                          }
                                        })
                                      : _vm._e(),
                                    !_vm.isLoadingPdf
                                      ? [
                                          _c(
                                            "object",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                height: "96%",
                                                "min-height": "600px"
                                              },
                                              attrs: {
                                                data: _vm.managementReport
                                                  .fileUrl
                                                  ? _vm.managementReport.fileUrl
                                                  : null
                                              }
                                            },
                                            [
                                              _c("embed", {
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "96%",
                                                  "min-height": "600px"
                                                },
                                                attrs: {
                                                  src: _vm.managementReport
                                                    .fileUrl
                                                    ? _vm.managementReport
                                                        .fileUrl
                                                    : null
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            ),
                            _c(
                              "v-tab-item",
                              {
                                attrs: {
                                  lazy: true,
                                  transition: false,
                                  "reverse-transition": false,
                                  value: "reports"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementPanel" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-inline-block" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "router-back",
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.back()
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("chevron_left")
                                            ]),
                                            _vm._v(" Terug ")
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-layout",
                                      { attrs: { wrap: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "py-0": "" } },
                                          [
                                            _c("ReportDatatable", {
                                              ref: "reportDatatable",
                                              attrs: {
                                                limit: 10,
                                                dataTableFilters:
                                                  _vm.tableFilters,
                                                autoRefetch: false,
                                                reportModel: _vm.reportModel
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }